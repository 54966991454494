import styled from "@emotion/styled";
import React from "react";

import { color_main } from "../../theme/styleGuide";

export const Tag = ({ children }: { children: React.ReactNode }) => {
  return <Wrapper>{children}</Wrapper>;
};

export default Tag;

const Wrapper = styled.div`
  font-size: 13px;
  font-family: inherit;
  height: 19px;
  width: 80px;
  border-radius: 5px;
  border: none;
  margin-right: 45px;

  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  background-color: ${color_main};
`;
