import React from "react";
import styled from "styled-components";
import PrimaryActionButton from "../../components/common/PrimaryActionButton";
import TagWide from "../../components/common/TagWide";
import Spacer from "../../components/layout/Spacer";
import { breakPointTablet } from "../../theme/styleGuide";
import { useForm } from "react-hook-form";


interface ConfirmPageProp {
  name: string;
  phone?: string;
  email: string;
  contents: string;
  modify: () => void;
}

const encode = (data: any) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};



const ConfirmPage = (props: ConfirmPageProp) => {
  
  console.log(props.name, props.phone, props.email, props.contents);

  return (

    <Wrapper>
      <div className="column">
        <h1 className="title">Content confirmation</h1>
        <div className="contents">
          
          <div className="row">
            <div className="contentsName">
              Name
              <TagWide>Required</TagWide>
            </div>
            <div className="entered">{props.name}</div>
          </div>
          <div className="row">
            <div className="contentsName">Phone</div>
            <div className="entered">{props.phone && props.phone}</div>
          </div>
          <div className="row">
            <div className="contentsName">
              Email
              <TagWide>Required</TagWide>
            </div>
            <div className="entered">{props.email}</div>
          </div>
          <div className="row">
            <div className="contentsName">
              Content
              <TagWide>Required</TagWide>
            </div>
            <div className="entered">{props.contents}</div>
          </div>
        </div>
        <div className="rowCenter">
          <div style={{ width: "100%", maxWidth: "250px" }}>
            <PrimaryActionButton
              isWhite={true}
              isValid={true}
              onClick={props.modify}
            >
              Edit
            </PrimaryActionButton>
          </div>
          <button type="submit" style={{ width: "100%", maxWidth: "250px" }}>
            <PrimaryActionButton 
            isWhite={false} 
            isValid={true}
            >
              Submit
            </PrimaryActionButton>
          </button>
        </div>
        <Spacer height={"200px"} />
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;

  .title {
    padding-top: 68px;
    font-size: 32px;
    line-height: 44px;
  }

  .column {
    width: 100%;
    max-width: 1280px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .contents {
    width: 100%;
    padding: 70px 282px 72px 109px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    @media screen and (max-width: ${breakPointTablet}) {
      padding-right: 10%;
      padding-left: 10%;
    }
  }

  .contentsName {
    font-size: 13px;
    line-height: 19px;
    min-width: 211px;
    display: flex;
    justify-content: space-between;
  }

  .row {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    @media screen and (max-width: ${breakPointTablet}) {
      flex-direction: column;
      gap: 10px;
    }
  }

  .rowCenter {
    margin-top: 60px;
    width: 100%;
    max-width: 678px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
    @media screen and (max-width: ${breakPointTablet}) {
      flex-direction: column;
      align-items: center;
      gap: 10px;
    }
  }

  .entered {
    word-break: break-all;
  }
`;

export default ConfirmPage;
